import styled from 'styled-components';

export default styled.div`
  text-align: center;
  background-color: #73838A;
  .rodal {
    width: 100%;
    min-height: 213px;
    display: flex;
    padding: 22px 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${props => props.theme.media.lg} {
      min-height: 200px;
    }
  }
  
  .rodal-header {
    h3 {
      margin-bottom: 0;
      font-size: 18px;
      font-family: Poppins;
      color: #FFFFFF;
      font-weight: 500;
      line-height: 29px;
      
      ${props => props.theme.media.lg} {
        text-align: left;
        font-size: 27px;
        margin-right: 5px;
      }
    }
  }
  
  
  .content-1 {
    ${props => props.theme.media.lg} {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
    }

    .header-container {
      ${props => props.theme.media.lg} {
        display: flex;
        flex-direction: column;
        justify-content: center;

      }
    }
  }

  .content-2 {
    ${props => props.theme.media.lg} {
      width: 600px;
    }
  }
  
  .form-container {
    max-width: 400px;
    max-height: 130px;

    ${props => props.theme.media.md} {
      max-width: 500px;
    }
    ${props => props.theme.media.lg} {
      max-width: 600px;
    }
  }
  
  .subtitle {
    font-size: 16px;
    max-width: 310px;
    color: #FFFFFF;
    line-height: 25px;
    margin: auto;
    margin-bottom: 5px;
    font-family: 'Poppins';

    ${props => props.theme.media.lg} {
      text-align: left;
      margin-left: 0px;
      margin-right: 5px;
      margin-top: 5px;
      font-size: 18px;
      max-width: 335px;
    }
  }


  .thanks-icon-desktop {
    display: none;
    ${props => props.theme.media.lg} {
      display: inline;
      float: right;
      position: relative;
      top: -30px;
      right: 111px;
    }
  }

  .thanks-icon-mobile {
    ${props => props.theme.media.lg} {
      display: none;
    }
  }

  .thanks {
    color: #FFFFFF;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    margin: auto;
    margin-top: 26px;
    margin-bottom: 10px;
    max-width: 267px;
    letter-spacing: 0px;

    ${props => props.theme.media.lg} {
      max-width: 100%;
      margin-bottom: 0px;
    }

    p {
      color: #FFFFFF;
      font-size: 20px;
      line-height: 26px;
      font-weight: 500;

      ${props => props.theme.media.lg} {
        display: inline;
      }
    }
    
  }

  .check-reminder {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 22px;
    margin: auto;
    margin-top: 10px;
    max-width: 317px;

    ${props => props.theme.media.lg} {
      max-width: 100%;
      margin-top: 0px;
    }

    p {
      ${props => props.theme.media.lg} {
        display: inline;
        max-width: 100%;
      }
    }
  } 

  .ground {
    z-index: 9998;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: grey;
    opacity: 0.7;
  }

  .close-button {
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 24px;
    color: #707070;
    cursor: pointer;
  }

  .button-container {
    display: flex;
    justify-content: center;
  }

  .no-thanks {
    margin-top: 12px;;
    text-align: center;
    font-size: 12px;
    font-family: Helvetica, Neue;
    color: #707070;
    cursor: pointer;
  }

  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .hidden {
    display: none;
  }

  .email-disclaimer {
    font-size: 8px;
  }

  form.mktoForm {
    width: 100% !important;
    text-align: center;

    label {
      display: none;
    }

    .form-control {
      font-family: Poppins;
      border: #B0B4B9 1px solid;
      color: #6B6B71;
      height: 44px;
      margin-bottom: 11px;
      border-radius: 8px;
    }

    button {
      padding: 10px 30px;
      font-size: 14px;
      font-weight: 300;
      font-family: 'Poppins';
      border-radius: 8px;
      color: #FFFFFF;
      background-color: #90C3C6;
      border: 2px solid #C8E2E2;
      width: 120px;
      height: 44px;
      margin-bottom: 11px;
    }

    .mktoButtonRow {
      display: inline-block;
      width: 120px;
      margin-left: 10px;
      height: 44px;

      span {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0 !important;
      }

      ${props => props.theme.media.lg} {
        position: relative;
        bottom: 3px;
        width: 150px;
      }
    }

    .mktoFormRow,
    .mktoFormCol {
      .mktoFieldWrap {
        .form-control {
          padding: 1rem;

          ${props => props.theme.media.lg} {
            margin-bottom: 0rem;
          }
        }

        input {
          font-family: Inter;

          ::placeholder {
            font-size: 0.75rem;
            font-weight: 300;
          }

          :-ms-input-placeholder {
            font-size: 0.75rem;
            font-weight: 300;
          }

          ::-webkit-input-placeholder {
            font-size: 0.75rem;
            font-weight: 300;
          }

          ${props => props.theme.media.lg} {
            min-width: 275px;

            ::placeholder {
              font-size: 1rem;
              font-weight: 300;
            }

            :-ms-input-placeholder {
              font-size: 1rem;
              font-weight: 300;
            }

            ::-webkit-input-placeholder {
              font-size: 1rem;
              font-weight: 300;
            }
          }
        }
      }
    }

    .mktoFormRow {
      display: none;
    }


  .wrapper-0 {
    max-width: 160px;
    display: inline-block;
    margin-right: 10px;
    max-height: 45px;
    width: 100%;

    ${props => props.theme.media.xs} {
      max-width: 170px;
    }

    ${props => props.theme.media.lg} {
      max-width: 275px;
    }
  }

  .wrapper-1 {
    max-width: 160px;
    max-height: 45px;
    display: inline-block;

    ${props => props.theme.media.xs} {
      max-width: 170px;
    }

    ${props => props.theme.media.lg} {
      max-width: 275px;
    }
  }

  .wrapper-2 {
    display: inline-block;
    max-height: 45px;
    max-width: 200px;

    ${props => props.theme.media.xs} {
      max-width: 220px;
    }

    ${props => props.theme.media.lg} {
      min-width: 400px;
      max-width: 400px;
    }

    .mktoFormCol {
      ${props => props.theme.media.lg} {
        width: 100%;
      }

      .mktoFieldWrap {
        ${props => props.theme.media.lg} {
          width: 100%;
        }
      }
    }
  }

  .mktoOffset {
    display: none;
    ${props => props.theme.media.lg} {
      display: none;
    }
  }

  .mktoButtonWrap {
    .btn {
      margin-top: 10px;
      ${props => props.theme.media.lg} {
        width: 100%;
      }
    }
  }
`;
