import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const form_id = 'mktoForm_1044';

const munchkin_id = '462-QJT-264';

const FooterForm = ({ onComplete, setIsLoaded }) => {
  const [state, setState] = useState({
    isRendered: false,
    isLoading: true,
    isFormSubmitted: false,
  });

  // Will pass the loaded state to the parent component.
  useEffect(() => {
    if (state.isRendered) {
      setIsLoaded(true);
    }
  }, [state.isRendered]);

  useEffect(() => {
    if (!state.isRendered && window.MktoForms2 && form_id === 'mktoForm_1044') {
      window.MktoForms2.loadForm(
        '//app-ab33.marketo.com',
        munchkin_id,
        '1044',

        function (form) {
          form.onValidate(function () {
            const values = form.vals();
            const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

            if (values.Email === '' || re.test(values.Email)) {
              form.submittable(true);
            } else {
              form.submittable(false);

              const emailElem = form.getFormElem().find('#Email');

              form.showErrorMessage(
                'Please enter a valid email',
                emailElem
              );
            }
          });

          form.onSuccess(() => {
            const values = form.vals();
            setState({
              ...state,
              isFormSubmitted: true,
            });

            onComplete();
            return false;
          });
        }
      );

      window.MktoForms2.whenRendered(function (formObject) {
        if (formObject.getId() === 1044) {
          const form = formObject.getFormElem()[0];
          const inputs = form.querySelectorAll('.mktoField.mktoHasWidth');

          for (let i = 0; i < inputs.length; i += 1) {
            const input = inputs[i];
            input.removeAttribute('style');
            input.removeAttribute('class');
            input.classList += ' form-control';
            input.classList += ` input-${i}`;

            const placeholder = `${input.parentElement
              .querySelector('label')
              .innerText.replace(/[^a-zA-Z ]/g, '')}`;

            // Add the optional text to the placeholder for First Name and Last Name field
            if (i < 2) {
              const optionalPlaceholder = placeholder += ' (optional)';
              input.setAttribute('placeholder', optionalPlaceholder);
            } else {
              input.setAttribute('placeholder', placeholder);
            }

          }

          const formRows = form.querySelectorAll('.mktoFormRow');
          for (let i = 0; i < formRows.length; i++) {
            const formRow = formRows[i];
            if (formRow) {
              formRow.classList += ` wrapper-${i}`;
            }
          }

          const button = form.querySelector('.mktoButton');
          if (button) {
            button.removeAttribute('class');
            button.classList += 'btn btn-lg btn-secondary';
            button.innerText = 'Subscribe';
          }

          setState({
            ...state,
            isLoading: false,
            isRendered: true,
          });
        }
      });
    }
  }, []);

  return (
    <div className="form-container">
      <form id={form_id} style={{ width: '100%' }} />
    </div>
  );
};

FooterForm.propTypes = {
  onComplete: PropTypes.func,
};

export default FooterForm;
