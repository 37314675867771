import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ReactSVG } from 'react-svg'
import ModalStyles from './ModalStyles';
import FooterForm from './FooterForm';

const FooterNewsModal = () => {
  const [stepOneComplete, setStepOneComplete] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true)
  const handleSubscribe = () => {
    setStepOneComplete(true);
    localStorage.setItem('alreadySubscribed', true);
  };

  return (
    <ModalStyles>
      {/* Load the popup once the form has completed loading */}
      <div className={isLoaded ? "rodal" : "hidden"}>
        {!stepOneComplete && (
          <div className="content-1">
            <div className="header-container">
              <div className="rodal-header">
                <h3>
                  Subscribe to our exclusive newsletter
                </h3>
              </div>
              <p className='subtitle'>
                for tax tips, insights, and more, curated to help your business grow.
              </p>
            </div>
            <FooterForm onComplete={handleSubscribe} setIsLoaded={setIsLoaded} />
          </div>
        )}

        {stepOneComplete && (
          <div className="content-2">
            <div className="rodal-header">
              <img
                height="58px"
                width="58px"
                alt="Thank you Icon"
                className="thanks-icon-desktop"
                src="/static/images/green-check.svg"
              />
              <div className="thanks">
                THANK YOU <p>FOR SUBSCRIBING!</p>
              </div>
              <img
                height="58px"
                width="58px"
                alt="Thank you Icon"
                className="thanks-icon-mobile"
                src="/static/images/green-check.svg"
              />
            </div>
            <div className="check-reminder">
              We hope you enjoy the <p>1-800Accountant monthly newsletter!</p>
            </div>
          </div>
        )}
      </div>
    </ModalStyles>
  );
};

FooterNewsModal.propTypes = {
};

export default FooterNewsModal;
